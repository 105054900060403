import request from '@/utils/request'

export function getList() {
    return request({
        url: '/type/list/', 
        method: 'get'
    })
}

export function addDownload(data) {
    return request({
        url: '/type/save', 
        method: 'post',
        data
    })
}

export function editDownload(id,data) {
    return request({
        url: '/type/edit?id=' + id, 
        method: 'post',
        data
    })
}

export function delDownload(id) {
    return request({
        url: '/type/del?id=' + id, 
        method: 'post',
    })
}